<template>
  <div>
    <Toolbar>
      <template #start>
        <div class="tab-bar">
          <router-link
            :to="{ name: 'NicheTrendResearch' }"
            :class="{
              'tab-button': true,
              'tab-active': isActiveTab('NicheTrendResearch'),
            }"
          >
            Trend Research
          </router-link>
        </div>
      </template>

      <template #end></template>
    </Toolbar>

    <router-view></router-view>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";

import Toolbar from "primevue/toolbar";

export default defineComponent({
  components: {
    Toolbar,
  },

  setup() {
    const route = useRoute();

    const activeTab = ref(1);

    const isActiveTab = (tab) => {
      return route.name === tab;
    };

    return {
      activeTab,

      isActiveTab,
    };
  },
});
</script>
